import { OPERATION_STATUS, STATUS_TYPES } from "../../utils/constant";
import { convertUTCDateToLocal } from "../Events/utils/formatDateTime";
import { InputTypes } from "../UiComponents/MultiFilter/types";

export const getAuditLogColumns = (templateHandler: any) => {
  return [
    {
      id: "operation",
      label: "Operation",
      searchable:true,
      sortable: true,
      exportable:true,
      input: {
        type: "dropDown" as InputTypes,
        dropDownOptions: OPERATION_STATUS,
      }
    },
    {
      id: "activity_datetime",
      label: "Activity Date Time",
      template: (row: any) => templateHandler(row, "activity_datetime"),
      sortable: true,
      exportable:true,
      resolver: ({activity_datetime}:any)=> convertUTCDateToLocal(activity_datetime)
    },
    {
      id: "status",
      label: "Status",
      searchable:true,
      sortable: true,
      input: {
        type: "dropDown" as InputTypes,
        dropDownOptions: STATUS_TYPES,
      },
      template: (row: any) => templateHandler(row, "status"),
      exportable:true
    },
    {
      id: "requested_by",
      sortable: true,
      searchable:true,
      label: "Requested By",
      exportable:true
    },
    {
      id:'metadata',
      label:'Data',
      exportable:true,
      isHidden: true,
      resolver: ({metadata}:any)=>{
        if (typeof metadata !== "string") {
          return JSON.stringify(metadata);
        }
      }
    },
    {
      id: "action",
      label: "",
      template: (row: any) => templateHandler(row, "action"),
    },
  ];
};
